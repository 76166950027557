import { Image } from 'react-bootstrap';
import { useQuery } from 'react-query';
import TrainingsService from '../../services/trainings.service';
import { Title, SubTitle } from '../pages/Title';
import logoHead from '../../images/nSL.jpg';
import { ContentDescription } from './';
import Trainings from './trainings';
import { QueryKey } from '../../data/QueryKey';
import { Paragraph } from '../core';
import { useEffect } from 'react';

export function QualiPage() {
	const {
		isLoading,
		error,
		data: trainings
	} = useQuery(QueryKey.quali, TrainingsService.getQuali);

	useEffect(() => {
		document.title = 'Qualifizierung | Fortbildung Berlin';
	}, []);

	return (
		<>
			<header>
				<Title title="FORTBILDUNGSANGEBOT FÜR DAS PÄDAGOGISCHE PERSONAL BERLINS" />

				<Image src={logoHead} alt="Logo im Kopf der Seite" fluid />
			</header>

			<main>
				<SubTitle title="Qualifizierung" align="center" />

				<ContentDescription>
					<Paragraph>
						Seit dem 1. Januar gibt es in Berlin ein neues Landesinstitut für Qualifizierung und
						Qualitätsentwicklung an Schulen (BLiQ). <br/>
						In diesem sollen perspektivisch die bisherigen Angebote der Aus-, Fort- und Weiterbildung in
						Qualifizierungsmaßnahmen münden. <br/>
						Die hier abgebildeten ersten modularen Angebote stellen Prototypen in dem Übergangsprozess dar.
					</Paragraph>
				</ContentDescription>

				<div style={{ padding: '1em'}}>
					<Trainings trainings={trainings} isLoading={isLoading} error={error} />
				</div>
			</main>
		</>
	);
}
