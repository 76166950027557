import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import colors from '../../common/colors';

export const ResponsiveHorizontalFlex = styled.div`
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
	justify-content: center; /* Zentriert den Inhalt horizontal */
	max-width: 100%; /* Verhindert Überlaufen */
	margin: 0 auto; /* Zentriert den gesamten Container */
	box-sizing: border-box; /* Berücksichtigt Padding und Border bei der Breitenberechnung */
`;

export const FlexItem = styled.div`
	flex: 1 1 300px; /* Setzt eine Standardbreite */
	min-width: 200px; /* Minimale Breite für bessere Lesbarkeit */
	max-width: 400px; /* Verhindert, dass Elemente zu groß werden */
	box-sizing: border-box; /* Berücksichtigt Padding und Border */
	padding: 10px; /* Füge einen Puffer hinzu */
	background-color: #fff; /* Beispiel: Hintergrundfarbe */
	border: 1px solid #ddd; /* Beispiel: leichte Umrandung */
	border-radius: 8px; /* Abgerundete Ecken */
`;

export const GroupHeader = styled(Alert)`
	margin: 15px;
	text-align: center;
`;

export const CardList = styled.ol`
	display: flex;
	flex-wrap: wrap;
	justify-content: center; /* Zentriert den Inhalt */
	gap: 15px; /* Abstände zwischen den Items */
	align-items: flex-start; /* Stellt sicher, dass die Items oben ausgerichtet sind */
	text-align: left;
	min-height: 150px;
	padding: 0;
	list-style-type: none;
	margin: 0 auto; /* Zentriert den Container */
`;

export const NoneStyledOrderedList = styled.ol`
	padding: 0;
	list-style-type: none;
`;

export const NoneStyledUnorderedList = styled.ul`
	padding: 0;
	list-style-type: none;
`;

export const Paragraph = styled.p`
	margin-bottom: 0;
`;

export const ParagraphWithSpace = styled.p``;

export const HorizontalDivider = styled.hr`
	color: ${colors.primaryDivider};
`

export const ModalHeader = styled.h2`
	font-size: 1.5rem;
`;

