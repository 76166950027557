export const QueryKey = {
	// trainings
	home: 'home',
	conferences: 'conferences',
	leadership: 'leadership',
	quali: 'quali',
	schifs: 'schifs',

	// symposiums
	symposiums: 'symposiums',

	// training
	training: 'training',

	// profile
	userProfile: 'userProfile',

	// base data
	subjects: 'subjects'
};
