import api from './api';

class TrainingsService {
	static defaultSearchData = {
		text: '',
		regionen_id: [0],
		bool: ' AND ',
		einrichtungen_barrierefrei: 0,
		gesamtstaedtische_schwerpunkte_id: 0,
		kurse_angebotsart: '',
		veranstaltungen_art: '',
		veranstaltungErsterTermin: '',
		limit: 0,
		limit_ab: 0,
		alleThemen: false
	};

	async getBySearch(searchData) {
		const { data } = await api.post(`/fortbildungenonlinerest/search`, searchData);
		return data;
	}

	// async getBySearchIntoTechnicalDays(searchData) {
	//     const { data } = await api.post(`/fortbildungenonlinerest/search-into-technical-days`, searchData);
	//     return data;
	// }

	// async getTrainingsHints() {
	//     const { data } = await api.get(`/fortbildungenonlinerest/information-on-training`);
	//     return data;
	// }

	async getCurrentHints() {
		const { data } = await api.get(`/fortbildungenonlinerest/current`);
		return data;
	}

	async getInformationHints() {
		const { data } = await api.get(`/fortbildungenonlinerest/information-on-training`);
		return data;
	}

	async getInstructionsForUse() {
		const { data } = await api.get(`/fortbildungenonlinerest/instructions-for-use`);
		return data;
	}

	async getContactPersons() {
		const { data } = await api.get(`/fortbildungenonlinerest/contact-persons`);
		return data;
	}

	async getCityWideFocalPoints() {
		const { data } = await api.get(`/fortbildungenonlinerest/city-wide-focal-points`);
		return data;
	}

	async getConferences(regions) {
		const { data } = await api.post(`/fortbildungenonlinerest/search?type=conferences`, {
			...TrainingsService.defaultSearchData,
			regionen_id: regions
		});
		return data;
	}

	async getSchifs(id) {
		const { data } = await api.post(`/fortbildungenonlinerest/search?type=schiF`, {
			...TrainingsService.defaultSearchData,
			einrichtungen_id: id > 0 ? id : id
		});
		return data;
	}

	async getLeaderships() {
		const { data } = await api.post(
			`/fortbildungenonlinerest/search?type=leaderships`,
			TrainingsService.defaultSearchData
		);
		return data;
	}

	async getQuali() {
		const { data } = await api.post(
			`/fortbildungenonlinerest/search?type=quali`,
			TrainingsService.defaultSearchData
		);
		return data;
	}

	async getTraining(id) {
		const { data } = await api.get(`/trainings/${id}`);
		return data;
	}

	async getSymposiums() {
		const { data } = await api.post(`/trainings/technical-days`);
		return data;
	}

	async getBySearchNoticeTechnicalDays() {
		const { data } = await api.post(`/trainings/technical-days-notice`);
		return data;
	}

	get TrainingPlaceholder() {
		return {
			id: 0,
			title: '',
			displayNumber: '',
			content: 'Lorem ipsum <strong> placeholder <strong>',
			date: '23.05.2022',
			location: 'placeholder',
			direction: 'placeholder',
			speakers: 'placeholder'
		};
	}
}
export default new TrainingsService();
