// import parse from 'html-react-parser';
import styled from 'styled-components';
import { getErrorMessage } from '../../common';
import { CardList, LoadingScreen } from '../core';
import { TrainingCard } from './trainingCard';

const NoResultText = styled.li`
	font-size: 1.25rem;
`;

export default function Trainings({ trainings, isLoading, error }) {
	if (error) return 'Beim Laden ist der folgende Fehler aufgetreten: ' + getErrorMessage(error);

	return (
		<section aria-label="Ergebnisliste">
			<LoadingScreen isLoading={isLoading}>
				<CardList>
					{trainings && trainings.length !== 0 ? (
						trainings?.map((training) => <TrainingCard key={training.id} training={training} />)
					) : (
						<NoResultText>Aktuell keine Fortbildungen gefunden</NoResultText>
					)}
				</CardList>
			</LoadingScreen>
		</section>
	);
}
